import React, { memo, useEffect, useState, useRef } from "react";
import useFetch from "use-http";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PageQuery, { getQueryStringFromPageQuery } from "common/types/PageQuery";
import { PagedResponse } from "common/types/PagedResponse";
import { useToaster } from "contexts/Toaster";
import Context from "./context";
import { SceneObject, Material, Light } from "./types";
import useOrganizations from "../Organizations/useOrganizations";

export const initialMaterialsQuery: PageQuery = {
	filters: {
		isPrivate: true,
		isPublic: true,
		category: [],
		tag: [],
	},
	limit: 24,
	page: 1,
	search: {
		text: "",
		fields: [],
	},
	sort: "meta.name",
};

export const initialObjectsQuery: PageQuery = {
	filters: {
		isPrivate: true,
		isPublic: true,
		category: [],
		tag: [],
	},
	limit: 24,
	page: 1,
	search: {
		text: "",
		fields: [],
	},
	sort: "meta.name",
};

export const initialLightsQuery: PageQuery = {
	filters: {
		isPrivate: true,
		isPublic: true,
		category: [],
		tag: [],
	},
	limit: 24,
	page: 1,
	search: {
		text: "",
		fields: [],
	},
	sort: "meta.name",
};

const LibraryProvider: React.FC = memo((props) => {
	const { children } = props;
	const { current: currentOrganization } = useOrganizations();
	const { shortid: shortidParam, kind: kindParam } = useParams<{
		shortid: string;
		kind: string;
	}>();
	const { t } = useTranslation();
	const history = useHistory();
	const { search } = useLocation();
	const toaster = useToaster();


	const [objects, setObjects] = useState<SceneObject[]>([]);
	const [objectsCount, setObjectsCount] = useState<number>(-1);
	const [currentObjectState, setCurrentObjectState] = useState<SceneObject>();
	const [objectsQuery, setObjectsQuery] = useState<PageQuery>(
		initialObjectsQuery,
	);
	const appendObjects = useRef<boolean>(false);
	const [pagedObjectsResponse, setPagedObjectsResponse] = useState<
		PagedResponse<SceneObject>
	>();


	const [materials, setMaterials] = useState<Material[]>([]);
	const [currentMaterialState, setCurrentMaterialState] = useState<Material>();
	const [materialsQuery, setMaterialsQuery] = useState<PageQuery>(
		initialMaterialsQuery,
	);
	const appendMaterials = useRef<boolean>(false);
	const [pagedMaterialsResponse, setPagedMaterialsResponse] = useState<
		PagedResponse<Material>
	>();


	const [lights, setLights] = useState<Light[]>([]);
	const [currentLightState, setCurrentLightState] = useState<Light>();
	const [lightsQuery, setLightsQuery] = useState<PageQuery>(
		initialLightsQuery,
	);
	const appendLights = useRef<boolean>(false);
	const [pagedLightsResponse, setPagedLightsResponse] = useState<
		PagedResponse<Light>
	>();


	// These are fetched once & passed down to AFrame
	const [allObjects, setAllObjects] = useState<SceneObject[]>([]);
	const [allMaterials, setAllMaterials] = useState<Material[]>([]);
	const [allLights, setAllLights] = useState<Light[]>([]);

	const isLibraryView = window.location.pathname.includes("library");

	const {
		get: getObjects,
		loading: loadingObjects,
		error: errorObjects,
		response: responseObjects,
	} = useFetch("objects");

	const {
		get: getMaterials,
		loading: loadingMaterials,
		error: errorMaterials,
		response: responseMaterials,
	} = useFetch("materials");

	const {
		get: getLights,
		loading: loadingLights,
		error: errorLights,
		response: responseLights,
	} = useFetch("lights");


	const { get: getAllObjects, response: responseAllObjects } = useFetch(
		"objects", {
			limit: 11000
		}
	);
	const { get: getAllMaterials, response: responseAllMaterials } = useFetch(
		"materials", {
			limit: 11000
		}
	);
	const { get: getAllLights, response: responseAllLights } = useFetch(
		"lights",
	);


	const fetchObjects = async () => {
		const queryString = getQueryStringFromPageQuery(objectsQuery);
		const response = await getObjects(`?${queryString}`);

		if (responseObjects.ok) {
			const results = appendObjects.current
				? [...objects, ...response?.results]
				: response.results;

			setObjects(results);
			setObjectsCount(response.totalResults !== undefined ? response.totalResults : -1);

			// TODO refactor the communication with hegias-3d in a way
			// that we dynamically update their list of objects like the following:
			// console.log("Setting allObjects=", [...allObjects, ...results]);
			// setAllObjects([...allObjects, ...results]);
			// The prop seems to update correctly, but hegias-3d doesn't seem to update the list internally then

			setPagedObjectsResponse(response);
			appendObjects.current = false;
		}
	};

	const fetchMaterials = async () => {
		const queryString = getQueryStringFromPageQuery(materialsQuery);
		const response = await getMaterials(`?${queryString}`);
		logger.debug("[LibraryProvider] fetchMaterials", { queryString });

		if (responseMaterials.ok) {
			const results = appendMaterials.current
				? [...materials, ...response?.results]
				: response.results;
			setMaterials(results);
			setPagedMaterialsResponse(response);
			appendMaterials.current = false;
		}
	};

	const fetchLights = async () => {
		const queryString = getQueryStringFromPageQuery(lightsQuery);
		const response = await getLights(`?${queryString}`);
		logger.debug("[LibraryProvider] fetchLights", { queryString });

		if (responseLights.ok) {
			const results = appendLights.current
				? [...lights, ...response?.results]
				: response.results;
			setLights(results);
			setPagedLightsResponse(response);
			appendLights.current = false;
		}
	};

	const fetchAllObjects = async () => {
		const queryString = getQueryStringFromPageQuery({
			...initialObjectsQuery,
			limit: 100000,
		});
		const response = await getAllObjects(`?${queryString}`);
		logger.debug("[LibraryProvider] fetchAllObjects", { queryString });

		if (responseAllObjects.ok) {
			// console.log("all objects=", response.results);
			setAllObjects(response.results);
		}
	};

	const fetchAllMaterials = async () => {
		const queryString = getQueryStringFromPageQuery({
			...initialMaterialsQuery,
			limit: 100000,
		});
		const response = await getAllMaterials(`?${queryString}`);
		logger.debug("[LibraryProvider] fetchAllMaterials", { queryString });

		if (responseAllMaterials.ok) {
			// console.log("all materials=", response.results);
			setAllMaterials(response.results);
		}
	};

	const fetchAllLights = async () => {
		const queryString = getQueryStringFromPageQuery({
			...initialLightsQuery,
			limit: 1000,
		});
		const response = await getAllLights(`?${queryString}`);
		logger.debug("[LibraryProvider] fetchAllLights", { queryString });

		if (responseAllLights.ok) {
			setAllLights(response.results);
		}
	};

	const loadMoreObjects = () => {
		if (!pagedObjectsResponse?.hasNextPage) {
			return;
		}

		appendObjects.current = true;
		setObjectsQuery((previousQuery) => ({
			...previousQuery,
			page: previousQuery.page + 1,
		}));
	};

	const loadMoreMaterials = () => {
		if (!pagedMaterialsResponse?.hasNextPage) {
			return;
		}

		appendMaterials.current = true;
		setMaterialsQuery((previousQuery) => ({
			...previousQuery,
			page: previousQuery.page + 1,
		}));
	};

	const loadMoreLights = () => {
		if (!pagedLightsResponse?.hasNextPage) {
			return;
		}

		appendLights.current = true;
		setLightsQuery((previousQuery) => ({
			...previousQuery,
			page: previousQuery.page + 1,
		}));
	};

	const setCurrentObject = async (idOrNull?: string | null) => {
		let targetObject;

		targetObject = objects?.find(
			(o) => o.id === idOrNull || o.shortid === idOrNull,
		) as SceneObject;

		if (!targetObject && idOrNull && !errorObjects) {
			const result = await getObjects(`/${shortidParam}/shortid`);

			if (responseObjects.ok) {
				targetObject = result;
			}
		}

		if (targetObject && isLibraryView) {
			history.replace(`/library/objects/${targetObject.shortid}`, search);
		}

		setCurrentObjectState(targetObject);
	};

	const resetCurrentObject = async () => {
		const result = await getObjects(`/${shortidParam}/shortid`);

		setCurrentObjectState(result);
	};

	const setCurrentMaterial = async (idOrNull?: string | null) => {
		let targetMaterial;

		targetMaterial = materials?.find(
			({ id, shortid }) => id === idOrNull || shortid === idOrNull,
		) as Material;

		if (!targetMaterial && idOrNull && !errorMaterials) {
			const result = await getMaterials(`/${shortidParam}/shortid`);

			if (responseMaterials.ok) {
				targetMaterial = result;
			}
		}

		if (targetMaterial && isLibraryView) {
			history.replace(
				`/library/materials/${targetMaterial.shortid}`,
				search,
			);
		}

		setCurrentMaterialState(targetMaterial);
	};

	const setCurrentLight = async (idOrNull?: string | null) => {
		let targetLight;

		targetLight = lights?.find(
			({ id, shortid }) => id === idOrNull || shortid === idOrNull,
		) as Light;

		if (!targetLight && idOrNull && !errorLights) {
			const result = await getLights(`/${shortidParam}/shortid`);

			if (responseLights.ok) {
				targetLight = result;
			}
		}

		if (targetLight && isLibraryView) {
			history.replace(
				`/library/lights/${targetLight.shortid}`,
				search,
			);
		}

		setCurrentLightState(targetLight);
	};

	/* For deep linking, if library page is loaded with shortid into params. */
	useEffect(() => {
		if (
			currentOrganization &&
			objects &&
			shortidParam &&
			kindParam === "objects"
		) {
			setCurrentObject(shortidParam);
		}

		if (
			currentOrganization &&
			materials &&
			shortidParam &&
			kindParam === "materials"
		) {
			setCurrentMaterial(shortidParam);
		}

		if (
			currentOrganization &&
			lights &&
			shortidParam &&
			kindParam === "lights"
		) {
			setCurrentLight(shortidParam);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objects, materials, lights, shortidParam, currentOrganization, kindParam]);

	useEffect(() => {
		if (currentOrganization) {
			fetchObjects();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objectsQuery, currentOrganization]);

	useEffect(() => {
		if (currentOrganization) {
			fetchAllObjects();
			fetchAllMaterials();
			fetchAllLights();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentOrganization]);

	useEffect(() => {
		if (currentOrganization) {
			fetchMaterials();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [materialsQuery, currentOrganization]);

	useEffect(() => {
		if (currentOrganization) {
			fetchLights();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lightsQuery, currentOrganization]);


	useEffect(() => {
		if (currentObjectState) {
			setCurrentMaterialState(undefined);
			// TODO add lights here and insert third useEffect
		}
	}, [currentObjectState]);

	useEffect(() => {
		if (currentMaterialState) {
			setCurrentObjectState(undefined);
			// TODO add lights here and insert third useEffect
		}
	}, [currentMaterialState]);


	useEffect(() => {
		if (errorObjects || errorMaterials || errorLights) {
			logger.debug(
				"[LibraryProvider] Critical error w/ objects/materials/lights",
				errorObjects,
				errorMaterials,
				errorLights,
			);

			toaster.error({
				message: t("app.global.msg.title.note"),
				description: t("msg.general.error"),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorObjects, errorMaterials, errorLights]);

	return (
		<Context.Provider
			value={{
				objects,
				objectsQuery,
				pagedObjects: pagedObjectsResponse,
				objectsCount,
				currentObject: currentObjectState,
				loadingObjects,
				errorObjects,
				setCurrentObject,
				resetCurrentObject,
				setObjectsQuery,
				loadMoreObjects,

				materials,
				materialsQuery,
				pagedMaterials: pagedMaterialsResponse,
				currentMaterial: currentMaterialState,
				loadingMaterials,
				errorMaterials,
				setCurrentMaterial,
				setMaterialsQuery,
				loadMoreMaterials,

				lights,
				lightsQuery,
				pagedLights: pagedLightsResponse,
				currentLight: currentLightState,
				loadingLights,
				errorLights,
				setCurrentLight,
				setLightsQuery,
				loadMoreLights,

				allObjects,
				allMaterials,
				allLights,
				fetchAllObjects,
				fetchAllMaterials,
				fetchAllLights,
			}}
		>
			{children}
		</Context.Provider>
	);
});

export default LibraryProvider;
